import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-699ddaf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-details-header" }
const _hoisted_2 = { class: "contact-details-header__left" }
const _hoisted_3 = { class: "contact-details-header__title" }
const _hoisted_4 = { class: "contact-details-header__title-text" }
const _hoisted_5 = { class: "contact-details-header__info" }
const _hoisted_6 = {
  key: 0,
  class: "circle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_contact_details_header_actions = _resolveComponent("contact-details-header-actions")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_tm_avatar, {
        "placeholder-custom-size": "22px",
        "custom-size": "47px",
        name: _ctx.contact.person.fullName,
        url: _ctx.contact.person.avatar,
        color: _ctx.contact.person.avatarColor,
        outlined: "",
        "outlined-with-shadow": "",
        class: "mr-2"
      }, null, 8, ["name", "url", "color"]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isSmMax ? 'span' : 'tm-editable-text'), {
              "min-height": "28px",
              "model-value": _ctx.contact.person.fullName,
              overflow: ""
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.contact.person.fullName), 1)
              ]),
              _: 1
            }, 8, ["model-value"]))
          ]),
          _createVNode(_component_tm_status, _mergeProps(_ctx.contact.type, { class: "flex-shrink-0" }), null, 16)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contact.infos, (item, i) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
              i
                ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, _toDisplayString(item), 1)
            ], 64))
          }), 128))
        ])
      ])
    ]),
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _createVNode(_component_contact_details_header_actions, {
        "sidebar-visible": _ctx.sidebarVisible,
        contact: _ctx.contact,
        "onUpdate:sidebarVisible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:sidebar-visible', $event))),
        onOpenNoteEditor: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open-note-editor')))
      }, null, 8, ["sidebar-visible", "contact"])
    ], true)
  ]))
}