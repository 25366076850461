import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-655571d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "empty-activity" }
const _hoisted_2 = { class: "empty-activity__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_tm_icon, {
      class: "tm-empty-state__icon subtle--text mb-1",
      "custom-size": "32px",
      name: _ctx.icon
    }, null, 8, ["name"]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.label), 1)
  ]))
}