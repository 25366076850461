import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "body-text-regular-14 emphasize--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_activity_card = _resolveComponent("activity-card")!

  return (_openBlock(), _createBlock(_component_activity_card, {
    title: _ctx.title,
    text: _ctx.text,
    "is-note": ""
  }, _createSlots({
    avatar: _withCtx(() => [
      _createVNode(_component_tm_avatar, {
        name: _ctx.title,
        color: "purple",
        size: "small",
        outlined: "",
        "outline-width": "1px"
      }, null, 8, ["name"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        icon: "edit",
        label: "Edit"
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "delete",
        label: "Delete"
      })
    ]),
    _: 2
  }, [
    (_ctx.date)
      ? {
          name: "date",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.calendar(_ctx.date)), 1)
          ])
        }
      : undefined
  ]), 1032, ["title", "text"]))
}