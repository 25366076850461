
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmButton from '@/components/shared/TmButton.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import { useRouter } from 'vue-router'
import { useChats } from '@/compositions/chats/chats'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'

export default defineComponent({
  components: {
    TmButton,
    TmDropdown,
    TmDropdownItem,
  },
  props: {
    sidebarVisible: {
      type: Boolean,
    },
    contact: {
      type: Object as PropType<ContactLine>,
      required: true,
    },
  },
  emits: ['update:sidebar-visible', 'open-note-editor'],
  setup() {
    const { isSmMax } = useBreakpoints()
    const router = useRouter()
    const {
      getChatData: chats,
      setActiveChat,
    } = useChats()
    const { openModal } = useModals()
    const { openModalEditContact, openBlockContactModal, openDeleteContactModal, initCall, openDownloadToEmailModal } = useContactsOpenModal()

    const openDownloadModal = () => {
      openModal('downloadModal')
    }

    const openChat = () => {
      router.push({ name: 'base.chats.open' })
      setTimeout(() => {
        const chat = chats.value.find(item => item.type === 'mobile')
        if (chat) {
          setActiveChat(chat)
        }
      }, 500)
    }

    return {
      isSmMax,
      openModal,
      openModalEditContact,
      openBlockContactModal,
      openDeleteContactModal,
      initCall,
      openChat,
      openDownloadModal,
      openDownloadToEmailModal,
    }
  },
})
