
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import ActivityCard from '@/components/pages/contacts/contactDetails/activities/ActivityCard.vue'
import TmPriority from '@/components/shared/TmPriority.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import { calendar, tillDueDate } from '@/services/dateTimeService'
import type { AvatarColorVariant, PriorityType } from '@/definitions/shared/types'
import { getDueDate } from '@/services/dueDate'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmButton,
    TmStatus,
    TmPerson,
    TmPriority,
    ActivityCard,
  },
  props: {
    title: {
      type: String,
    },
    assigneeName: {
      type: String,
    },
    priority: {
      type: String as PropType<PriorityType>,
      required: true,
    },
    assigneeAvatarColor: {
      type: String as PropType<AvatarColorVariant>,
    },
    date: {
      type: [String, Date],
    },
  },
  setup() {
    return {
      calendar,
      getDueDate,
      tillDueDate,
    }
  },
})
