
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import { useModals } from '@/compositions/modals'
import useContactsOrganizationsOpenModal from '@/compositions/contacts/useContactsOrganizationsOpenModal'
import type { ContactOrganization } from '@/definitions/contacts/organizations/types'
import DetailsSidebarHeader from '@/components/shared/templates/details/sidebar/DetailsSidebarHeader.vue'
import ActionButton from '@/components/shared/templates/ActionButton.vue'

export default defineComponent({
  components: {
    DetailsSidebarHeader,
    ActionButton,
  },
  props: {
    organization: {
      type: Object as PropType<ContactOrganization>,
      default: null,
    },
  },
  emits: ['show-search', 'close'],
  setup() {
    const { openModal } = useModals()
    const { openEditOrganizationsModal } = useContactsOrganizationsOpenModal()

    return {
      openModal,
      openEditOrganizationsModal,
    }
  },
})
