
import {
  computed,
  defineComponent, reactive, ref
} from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
import ContactDetailsExpansion from '@/components/pages/contacts/contactDetails/ContactDetailsExpansion.vue'
import ActivityNote from '@/components/pages/contacts/contactDetails/activities/ActivityNote.vue'
import ActivityTask from '@/components/pages/contacts/contactDetails/activities/ActivityTask.vue'
import ActivityScheduled from '@/components/pages/contacts/contactDetails/activities/ActivityScheduled.vue'
import ActivityLog from '@/components/pages/contacts/contactDetails/activities/ActivityLog.vue'
import {
  activitiesNotes,
  activitiesTasks,
  activitiesScheduled,
  activitiesLog,
  filterTypeOptions,
  filterDateOptions
} from '@/definitions/contacts/activities/new/data'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'
import FilterButton from '@/components/shared/filters/FilterButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import { useModes } from '@/compositions/modes'
import EmptyActivity from '@/components/pages/contacts/contactDetails/activities/EmptyActivity.vue'
import SearchNoResults from '@/components/shared/search/SearchNoResults.vue'

export default defineComponent({
  components: {
    SearchNoResults,
    EmptyActivity,
    TmSearch,
    TmDropdownItem,
    TmDropdown,
    FilterButton,
    TmButton,
    ActivityLog,
    ActivityScheduled,
    ActivityTask,
    ActivityNote,
    ContactDetailsExpansion,
    TmScrollbar,
  },
  emits: ['open-note-editor', 'update:sidebar-visible'],
  setup() {
    const { openModal } = useModals()
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    const activitiesLogValue = ref([...activitiesLog])
    const isActivityLoading = ref(false)
    const isLoadActivityVisible = ref(true)
    const loadActivity = () => {
      isActivityLoading.value = true
      setTimeout(() => {
        activitiesLogValue.value.push(...activitiesLog)
        isActivityLoading.value = false
        isLoadActivityVisible.value = false
      }, 1000)
    }

    const search = ref('')
    const filters = reactive({
      type: filterTypeOptions[0],
      date: filterDateOptions[0],
    })

    const filteredActivities = computed(() => {
      const activeFilterType = filterTypeOptions.find(item => item.value === filters.type.value)?.activityType
      const checkDateFunc = filterDateOptions.find(item => item.value === filters.date.value)?.func
      let result = activitiesLogValue.value.filter(item => activeFilterType?.includes(item.type))
      if (checkDateFunc) {
        result = result.filter(item => checkDateFunc(item.date))
      }
      if (search.value.length) {
        result = result.filter(item => item.title.includes(search.value))
      }
      return result
    })

    return {
      openModal,
      search,
      activitiesNotes,
      activitiesTasks,
      activitiesScheduled,
      activitiesLog,
      activitiesLogValue,
      loadActivity,
      isActivityLoading,
      isLoadActivityVisible,
      filterTypeOptions,
      filterDateOptions,
      filters,
      filteredActivities,
      isEmptyMode,
      isEmptyHalfMode,
    }
  },
})
