import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_organization_details_sidebar_header = _resolveComponent("organization-details-sidebar-header")!
  const _component_details_sidebar_search = _resolveComponent("details-sidebar-search")!
  const _component_tm_recipients = _resolveComponent("tm-recipients")!
  const _component_organization_details_sidebar_body = _resolveComponent("organization-details-sidebar-body")!
  const _component_details_grid_sidebar = _resolveComponent("details-grid-sidebar")!

  return (_openBlock(), _createBlock(_component_details_grid_sidebar, {
    "sidebar-sections": _ctx.sidebarSections,
    "no-padding": "",
    "small-header": _ctx.isMdMax
  }, {
    "sidebar-header": _withCtx(() => [
      (!_ctx.showSearch)
        ? (_openBlock(), _createBlock(_component_organization_details_sidebar_header, {
            key: 0,
            search: _ctx.search,
            "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            organization: _ctx.organization,
            onShowSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSearch = !_ctx.showSearch)),
            onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
          }, null, 8, ["search", "organization"]))
        : (_openBlock(), _createBlock(_component_details_sidebar_search, {
            key: 1,
            search: _ctx.search,
            "onUpdate:search": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search) = $event)),
            onHideSearch: _ctx.hideSearch
          }, null, 8, ["search", "onHideSearch"]))
    ]),
    "sidebar-section-contacts": _withCtx(() => [
      _createVNode(_component_tm_recipients, {
        items: _ctx.recipients,
        class: "px-4",
        onMoreClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.openModal('contactsOrganizationsTableModal')))
      }, null, 8, ["items"])
    ]),
    "sidebar-section-about-organization": _withCtx(() => [
      _createVNode(_component_organization_details_sidebar_body, {
        search: _ctx.search,
        organization: _ctx.organization
      }, null, 8, ["search", "organization"])
    ]),
    _: 1
  }, 8, ["sidebar-sections", "small-header"]))
}