
import { defineComponent } from 'vue'
import TmSearch from '@/components/shared/TmSearch.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmSearch,
    TmButton,
  },
  props: {
    search: {
      type: String,
    },
  },
  emits: ['hide-search', 'update:search'],
})
