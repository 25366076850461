
import { defineComponent, computed } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { IconSize, SizeProp } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmButton,
    TmTooltip,
  },
  props: {
    iconSize: {
      type: String as SizeProp<IconSize>,
      default: 'xLarge',
    },
    icon: {
      type: String,
    },
    text: {
      type: String,
    },
    mobileView: {
      type: Boolean,
    },
    hideTooltip: {
      type: Boolean,
    },
  },
  setup(props) {
    const showTooltip = computed(() => props.text && !props.mobileView && !props.hideTooltip)

    return {
      showTooltip,
    }
  },
})
