
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import kebabCase from 'lodash/kebabCase'

export default defineComponent({
  props: {
    bordered: {
      type: Boolean,
    },
    size: {
      type: String as PropType<'' | 'small' | 'medium'>,
    },
    title: {
      type: String,
      default: 'No results with this request',
    },
    icon: {
      type: String,
      default: 'search_off',
    },
    text: {
      type: String,
      default: 'Please try to change your search request',
    },
  },
  setup() {
    return {
      kebabCase,
    }
  },
})
