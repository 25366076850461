
import type { PropType } from 'vue'
import { computed, defineComponent, ref } from 'vue'
import DetailsSidebarSearch from '@/components/shared/templates/details/sidebar/DetailsSidebarSearch.vue'
import DetailsGridSidebar from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import OrganizationDetailsSidebarHeader from '@/components/pages/contacts/organizationDetails/OrganizationDetailsSidebarHeader.vue'
import OrganizationDetailsSidebarBody from '@/components/pages/contacts/organizationDetails/OrganizationDetailsSidebarBody.vue'
import type { ContactOrganization } from '@/definitions/contacts/organizations/types'
import { useBreakpoints } from '@/compositions/breakpoints'
import type { SidebarSectionType } from '@/components/shared/templates/details/DetailsGridSidebar.vue'
import TmRecipients from '@/components/shared/TmRecipients.vue'
import { getTableData } from '@/services/tableService'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmRecipients,
    DetailsSidebarSearch,
    DetailsGridSidebar,
    OrganizationDetailsSidebarHeader,
    OrganizationDetailsSidebarBody,
  },
  props: {
    organization: {
      type: Object as PropType<ContactOrganization>,
      default: null,
    },
  },
  emits: ['close'],
  setup() {
    const { isMdMax } = useBreakpoints()
    const { openModal } = useModals()
    const showSearch = ref(false)
    const search = ref('')
    const hideSearch = () => {
      showSearch.value = false
      search.value = ''
    }

    const recipients = ref(getTableData('recipientsList'))
    const sidebarSections = computed<SidebarSectionType[]>(() => search.value.length > 2
      ? []
      : [
          {
            label: 'Contacts',
            facet: 44,
            expanded: true,
          },
          {
            label: 'About organization',
            expanded: true,
          },
        ])

    return {
      hideSearch,
      recipients,
      isMdMax,
      showSearch,
      sidebarSections,
      openModal,
      search,
    }
  },
})
