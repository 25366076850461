import type {
  ActivityNoteType,
  ActivityTaskType,
  ActivityScheduledType,
  ActivityLogType,
  ActivityLogStatus,
  ActivityLogName,
  ActivityScheduledStatus
} from '@/definitions/contacts/activities/new/types'
import { isLast30Days, isLast7Days, isToday, isYesterday } from '@/services/dateTimeService'

// maps
export const activityLogStatusIcon: Record<ActivityLogStatus, { [key: string]: string; }> = {
  Delivered: {
    icon: 'check_circle_outline',
    color: 'success',
  },
  Sent: {
    icon: 'tmi-send-outline',
    color: 'blue',
  },
  Rejected: {
    icon: 'warning_amber',
    color: 'warning',
  },
  Failed: {
    icon: 'cancel',
    color: 'error',
  },
}

export const activityLogHeaderMap: Record<ActivityLogName, {[key: string]: string;}> = {
  outboundTextMessage: {
    icon: 'tmi-chat-outline',
    class: 'blue--text',
    title: 'Outbound text message',
  },
  inboundTextMessage: {
    icon: 'tmi-chat-outline',
    class: 'green--text',
    title: 'Inbound text message',
  },
  outboundCall: {
    icon: 'tmi-phone-forwarded',
    class: 'blue--text',
    title: 'Outbound call',
  },
  inboundCall: {
    icon: 'tmi-phone-callback-outline',
    class: 'green--text',
    title: 'Inbound call ',
  },
  missedCall: {
    icon: 'tmi-phone-missed-outline',
    class: 'red--text',
    title: 'Missed call',
  },
  forwardedCall: {
    icon: 'tmi-phone-forwarded2-outline',
    class: 'orange--text',
    title: 'Forwarded call',
  },
}

export const activityScheduleIcon: Record<ActivityScheduledStatus, { [key: string]: string; }> = {
  Scheduled: {
    icon: 'event',
    color: 'success',
  },
  Paused: {
    icon: 'pause',
    color: 'blue',
  },
}

// mocks
export const activitiesNotes: ActivityNoteType[] = [
  {
    text: 'Have you written an entire note or document in caps lock without realizition.Have you written an entire note or document in caps lock without realizition.Have you written an entire note or document in caps lock without realizition.',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    user: {
      name: 'John',
      surname: 'Doe',
      avatarColor: 'purple',
    },
  },
  {
    text: 'Have you written an entire note or document in caps lock without realizition.',
    date: new Date(+new Date() - (6 * 24 * 48 * 60 * 1000)).toJSON(),
    user: {
      name: 'John',
      surname: 'Doe',
      avatarColor: 'purple',
    },
  },
]

export const activitiesTasks: ActivityTaskType[] = [
  {
    title: 'Product demo call',
    priority: '100',
    date: new Date(+new Date() + (1 * 30 * 48 * 60 * 1000)).toJSON(),
    assignee: {
      fullname: 'Jane Crowford',
      avatarColor: 'purple',
    },
    status: 'open',
  },
  {
    title: 'Product demo call',
    priority: '100',
    status: 'open',
  },
]

export const activitiesScheduled: ActivityScheduledType[] = [
  {
    date: new Date(+new Date() + (2 * 48 * 60 * 1000)).toJSON(),
    text: 'Could you please provide your username?',
    status: 'Scheduled',
  },
  {
    date: new Date(+new Date() + (1 * 30 * 48 * 60 * 1000)).toJSON(),
    text: 'Hello, do you need any help from our support team today?',
    status: 'Paused',
  },
  {
    date: new Date(+new Date() + (6 * 25 * 48 * 60 * 1000)).toJSON(),
    text: 'A friendly reminder about the upcoming meeting',
    status: 'Scheduled',
  },
]

export const activitiesLog: ActivityLogType[] = [
  {
    type: 'outboundTextMessage',
    title: activityLogHeaderMap.outboundTextMessage.title,
    date: new Date(+new Date() - (1 * 2 * 48 * 60 * 1000)).toJSON(),
    text: 'Could you please provide your username?',
    status: 'Delivered',
  },
  {
    type: 'inboundTextMessage',
    title: activityLogHeaderMap.inboundTextMessage.title,
    date: new Date(+new Date() - (1 * 25 * 48 * 60 * 1000)).toJSON(),
    text: 'Certainly! I can assist you with resetting your password. Could you please provide your username?',
    status: 'Sent',
  },
  {
    type: 'outboundTextMessage',
    title: activityLogHeaderMap.outboundTextMessage.title,
    date: new Date(+new Date() - (2 * 24 * 48 * 60 * 1000)).toJSON(),
    text: 'Could you please provide your username?',
    status: 'Rejected',
  },
  {
    type: 'outboundTextMessage',
    title: activityLogHeaderMap.outboundTextMessage.title,
    date: new Date(+new Date() - (2 * 25 * 48 * 60 * 1000)).toJSON(),
    text: 'Certainly! I can assist you with resetting your password. Could you please provide your username?',
    status: 'Failed',
  },
  {
    type: 'outboundCall',
    title: activityLogHeaderMap.outboundCall.title,
    date: new Date(+new Date() - (3 * 24 * 48 * 60 * 1000)).toJSON(),
    info: {
      duration: '00:02:06',
      number: 'From: +44 7418 373305 (US support)',
    },
  },
  {
    type: 'inboundCall',
    title: activityLogHeaderMap.inboundCall.title,
    date: new Date(+new Date() - (20 * 25 * 48 * 60 * 1000)).toJSON(),
    info: {
      duration: '00:02:06',
      number: 'To: +44 7418 373305 (US support)',
    },
  },
  {
    type: 'missedCall',
    title: activityLogHeaderMap.missedCall.title,
    date: new Date(+new Date() - (20 * 24 * 48 * 60 * 1000)).toJSON(),
    info: {
      number: 'To: +44 7418 373305 (US support)',
    },
  },
  {
    type: 'forwardedCall',
    title: activityLogHeaderMap.forwardedCall.title,
    date: new Date(+new Date() - (25 * 25 * 48 * 60 * 1000)).toJSON(),
    info: {
      duration: '00:02:06',
      number: 'To: +44 7418 373305 (US support)',
    },
  },
]

// filters
export const filterTypeOptions = [
  {
    label: 'All activities',
    value: 'all',
    activityType: ['outboundTextMessage', 'inboundTextMessage', 'inboundCall', 'missedCall', 'forwardedCall'],
  },
  { label: 'Sent SMS', value: 'sendSms', activityType: ['outboundTextMessage'] },
  { label: 'Received SMS', value: 'receivedSms', activityType: ['inboundTextMessage'] },
  { label: 'Calls', value: 'calls', activityType: ['inboundCall', 'missedCall', 'forwardedCall'] },
]
export const filterDateOptions = [
  { label: 'All time', value: 'all' },
  { label: 'Last 30 days', value: 'month', func: isLast30Days },
  { label: 'Last 7 days', value: 'week', func: isLast7Days },
  { label: 'Yesterday', value: 'yesterday', func: isYesterday },
  { label: 'Today', value: 'today', func: isToday },
]
