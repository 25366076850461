import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_fields_container = _resolveComponent("tm-fields-container")!
  const _component_tm_system_info = _resolveComponent("tm-system-info")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.name,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.name) = $event)),
      label: "Name",
      type: "fullName"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.domains,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.domains) = $event)),
      label: "Domain",
      type: "domains"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.emails,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.emails) = $event)),
      label: "Email",
      type: "email"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.phones,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.phones) = $event)),
      label: "Phone",
      type: "phone"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.ownerValue,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.ownerValue) = $event)),
      label: "Owner",
      "avatar-size": "xxSmall",
      size: "small",
      type: "assignee",
      "empty-placeholder": "No owner",
      options: _ctx.assigneeOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.statusValue,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.statusValue) = $event)),
      label: "Type",
      type: "selectStatus",
      options: _ctx.statusOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.primaryContactValue,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.primaryContactValue) = $event)),
      label: "Primary contact",
      type: "assignee",
      "avatar-size": "xxSmall",
      size: "small",
      "empty-placeholder": "No primary contact",
      options: _ctx.assigneeOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.country,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.country) = $event)),
      label: "Country",
      type: "selectCountry",
      "flag-size": "xSmall"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.timezoneCurrent,
      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.timezoneCurrent) = $event)),
      label: "Timezone",
      type: "selectTimezone"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.organizationSizeValue,
      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.organizationSizeValue) = $event)),
      label: "Size",
      type: "select",
      options: _ctx.organizationSizeOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.industryValue,
      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.industryValue) = $event)),
      label: "Industry",
      type: "select",
      options: _ctx.industryOptions
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.socialMedia,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.socialMedia) = $event)),
      label: "Social media",
      type: "socialMedia"
    }, _ctx.commonProps), null, 16, ["modelValue"]),
    _createVNode(_component_tm_fields_container, _mergeProps({
      modelValue: _ctx.tagsValue,
      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.tagsValue) = $event)),
      label: "Tags",
      type: "tags",
      "one-line": "",
      "hide-input": "",
      options: _ctx.tagsOptions,
      placeholder: "Add tags"
    }, _ctx.commonProps), null, 16, ["modelValue", "options"]),
    _createVNode(_component_tm_system_info, { items: _ctx.interactions }, null, 8, ["items"]),
    _createVNode(_component_tm_system_info, { items: _ctx.systemInfo }, null, 8, ["items"])
  ]))
}