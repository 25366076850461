
import { defineComponent, ref } from 'vue'
import OrganizationDetailsSidebar from '@/components/pages/contacts/organizationDetails/OrganizationDetailsSidebar.vue'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'
import DetailsTemplate from '@/components/shared/templates/details/DetailsTemplate.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import type { ContactOrganization } from '@/definitions/contacts/organizations/types'
import OrganizationDetailsMain from '@/components/pages/contacts/organizationDetails/OrganizationDetailsMain.vue'

export default defineComponent({
  components: {
    OrganizationDetailsMain,
    DetailsTemplate,
    OrganizationDetailsSidebar,
  },
  setup() {
    const breadcrumbs = ref<BreadcrumbsLink[]>([
      {
        label: 'Organizations',
        name: 'base.contacts.organizations',
      },
      {
        label: 'eBay',
      },
    ])

    const contact = ref<ContactLine>({
      person: {
        firstName: 'Ralph',
        lastName: 'Edwards',
        fullName: 'eBay',
        avatarColor: 'white',
        avatar: require('@/assets/images/logos/ebay2.svg'),
      },
      infos: ['34 contacts'],
      type: {
        name: 'customer',
        color: 'green',
        outline: true,
      },
      company: 'Kuhn Group',
      country: {
        name: 'United States',
        id: 'us',
      },
    })
    const organization = ref<ContactOrganization>({
      organization: {
        organizationName: 'eBay',
        avatarColor: 'white',
        avatar: require('@/assets/images/logos/ebay2.svg'),
      },
      domains: ['ebay.com'],
      contacts: 34,
      primaryContact: {
        uid: '16d96cfa-35b2-4b80-8cf4-193ba1d58b1d',
        firstName: 'Alexandria',
        lastName: 'Wehner',
        fullName: 'Alexandria Wehner',
        avatar: 'https://randomuser.me/api/portraits/women/13.jpg',
        avatarColor: 'blue',
      },
      country: {
        name: 'Denmark',
        id: 'dk',
      },
      type: {
        name: 'partner',
        outline: true,
        color: 'blue',
      },
      lastUpdated: '2023-10-20T15:31:49.727Z',
      dateCreated: '2023-10-21T00:26:46.572Z',
    })

    return {
      breadcrumbs,
      organization,
      contact,
    }
  },
})
