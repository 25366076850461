import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_organization_details_main = _resolveComponent("organization-details-main")!
  const _component_organization_details_sidebar = _resolveComponent("organization-details-sidebar")!
  const _component_details_template = _resolveComponent("details-template")!

  return (_openBlock(), _createBlock(_component_details_template, {
    breadcrumbs: _ctx.breadcrumbs,
    name: "organization"
  }, _createSlots({
    default: _withCtx(({ toggleSidebar, sidebarVisible }) => [
      _createVNode(_component_organization_details_main, {
        contact: _ctx.contact,
        "sidebar-visible": sidebarVisible,
        "onUpdate:sidebarVisible": toggleSidebar
      }, null, 8, ["contact", "sidebar-visible", "onUpdate:sidebarVisible"])
    ]),
    sidebar: _withCtx(({ toggleSidebar }) => [
      _createVNode(_component_organization_details_sidebar, {
        organization: _ctx.organization,
        onClose: toggleSidebar
      }, null, 8, ["organization", "onClose"])
    ]),
    _: 2
  }, [
    _renderList(_ctx.$slots, (index, name) => {
      return {
        name: name,
        fn: _withCtx((data) => [
          _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(data)))
        ])
      }
    })
  ]), 1032, ["breadcrumbs"]))
}