import { format, tillDueDate } from '@/services/dateTimeService'
import { capitalize } from '@/services/utils'

type DueDate = {
  textValue: string;
  textClass: string;
  iconName: string;
}

const parseSoonMargin = (val: string) => {
  const map: Record<string, number> = {
    s: 1000,
    m: 60 * 1000,
    h: 60 * 60 * 1000,
    d: 24 * 60 * 60 * 1000,
  }

  return val.match(/(\d*\w)/g)!.reduce((acc: number, el: string) => {
    const key = el[el.length - 1]
    const int = Number.parseInt(el, 10)
    acc += int * map[key]

    return acc
  }, 0)
}

const overdueOption = {
  icon: 'error',
  className: 'red--text font-weight-semi-bold',
}
const soonOption = {
  icon: 'notifications',
  className: 'orange--text font-weight-semi-bold',
}

export const getDueDate = (date: number | string | Date, soonMargin = '2d'): DueDate => {
  let textValue = ''
  let textClass = ''
  let iconName = ''
  const internalDate = new Date(date)
  const soonMarginInt = parseSoonMargin(soonMargin)
  const isSoon = +internalDate - +new Date() - soonMarginInt < 0

  const { hours, isToday, isTomorrow, isYesterday, fromNow, isOverdue, isSameYear } = tillDueDate(internalDate)
  const timeFormat = ' h:mm a'
  const dateTimeFormat = (isSameYear ? 'D MMM' : 'D MMM YYYY') + timeFormat

  if (date) {
    if (!isToday && !isTomorrow && !isOverdue) {
      // due date is later than tomorrow
      textValue = format(internalDate, dateTimeFormat)
    } else if ((isToday || isTomorrow) && hours >= 2) {
      // due date is today or tomorrow, but later than 2 hours
      textValue = (isToday ? 'Today' : 'Tomorrow') + format(internalDate, timeFormat)
    } else if (isToday && hours >= 0 && !isOverdue) {
      // due date less than 2 hours
      textValue = capitalize(fromNow as string)
    } else if (isToday && hours >= -1 && isOverdue) {
      /// OVERDUE ///
      // overdue less than 2 hours
      textValue = capitalize(fromNow as string)
    } else if ((isToday || isYesterday) && isOverdue) {
      // overdue is today or yesterday, older than 2 hours
      textValue = (isToday ? 'Today' : 'Yesterday') + format(internalDate, timeFormat)
    } else {
      // overdue older than yesterday
      textValue = format(internalDate, dateTimeFormat)
    }
  }
  if (isSoon) {
    textClass = soonOption.className
    iconName = soonOption.icon
  }
  if (isOverdue) {
    textClass = overdueOption.className
    iconName = overdueOption.icon
  }

  return {
    textValue,
    textClass,
    iconName,
  }
}
