import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d3c03af"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "action-button__text"
}
const _hoisted_2 = { class: "font-weight-normal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createBlock(_component_tm_button, {
    flat: "",
    class: _normalizeClass(["action-button", {
      'action-button--mobile-view': _ctx.mobileView,
    }]),
    size: "medium",
    "icon-only": !_ctx.mobileView,
    color: "transparent"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_tm_icon, {
        name: _ctx.icon,
        size: _ctx.iconSize
      }, null, 8, ["name", "size"]),
      (_ctx.mobileView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.text), 1))
        : _createCommentVNode("", true),
      (_ctx.showTooltip)
        ? (_openBlock(), _createBlock(_component_tm_tooltip, { key: 1 }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.text), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["icon-only", "class"]))
}