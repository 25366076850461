import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_dropdown_item = _resolveComponent("tm-dropdown-item")!
  const _component_activity_card = _resolveComponent("activity-card")!

  return (_openBlock(), _createBlock(_component_activity_card, {
    title: "Text message",
    icon: "tmi-event"
  }, {
    status: _withCtx(() => [
      _createVNode(_component_tm_status, {
        icon: _ctx.activityScheduleIcon[_ctx.status].icon,
        color: _ctx.activityScheduleIcon[_ctx.status].color,
        name: _ctx.status
      }, null, 8, ["icon", "color", "name"])
    ]),
    actions: _withCtx(() => [
      _createVNode(_component_tm_dropdown_item, {
        icon: "edit",
        label: "Edit"
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "pause",
        label: "Pause"
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "visibility",
        label: "View details"
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "assignment",
        label: "Save as template"
      }),
      _createVNode(_component_tm_dropdown_item, {
        icon: "delete",
        label: "Delete"
      })
    ]),
    _: 1
  }))
}