import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_button = _resolveComponent("action-button")!
  const _component_details_sidebar_header = _resolveComponent("details-sidebar-header")!

  return (_openBlock(), _createBlock(_component_details_sidebar_header, null, {
    default: _withCtx(() => [
      _createVNode(_component_action_button, {
        icon: "edit",
        text: "Edit",
        class: "neutral--text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openEditOrganizationsModal(_ctx.organization)))
      }),
      _createVNode(_component_action_button, {
        icon: "search",
        text: "Search",
        class: "neutral--text",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('show-search')))
      }),
      _createVNode(_component_action_button, {
        icon: "close",
        text: "Close",
        class: "neutral--text",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close')))
      })
    ]),
    _: 1
  }))
}