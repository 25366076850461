
import { defineComponent } from 'vue'
import ActionButton from '@/components/shared/templates/ActionButton.vue'

export default defineComponent({
  components: {
    ActionButton,
  },
  emits: ['close', 'show-search'],
})
