
import { defineComponent } from 'vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import { calendar } from '@/services/dateTimeService'
import Highlight from '@/components/shared/Highlight.vue'

export default defineComponent({
  components: {
    Highlight,
    TmDropdown,
    TmButtonMore,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    search: {
      type: String,
    },
    date: {
      type: String,
    },
    isLog: {
      type: Boolean,
    },
    isNote: {
      type: Boolean,
    },
    icon: {
      type: String,
    },
  },
  setup() {
    return {
      calendar,
    }
  },
})
