import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-584dadef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chats-empty__title" }
const _hoisted_2 = { class: "chats-empty__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["chats-empty", {
      'chats-empty--bordered': _ctx.bordered,
      [`chats-empty--${_ctx.kebabCase(_ctx.size)}`]: _ctx.size,
    }])
  }, [
    _createVNode(_component_tm_icon, {
      name: _ctx.icon,
      "custom-size": !_ctx.size ? '72px' : '56px'
    }, null, 8, ["name", "custom-size"]),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 2))
}