
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmButton,
  },
  props: {
    positionId: {
      type: Number,
    },
    positionsTotal: {
      type: Number,
    },
    positionName: {
      type: String,
    },
  },
  emits: ['prev', 'next'],
})
