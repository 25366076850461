
import type { PropType } from 'vue'
import { defineComponent, ref, watch, onMounted, onUnmounted } from 'vue'
import TmPositionsSwitch from '@/components/shared/TmPositionsSwitch.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import TmOverlay from '@/components/layout/TmOverlay.vue'
import { useBreakpoints } from '@/compositions/breakpoints'
import { useRoute } from 'vue-router'
import type { BreadcrumbsLink } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmOverlay,
    PageContent,
    TmPositionsSwitch,
  },
  props: {
    breadcrumbs: {
      type: Array as PropType<BreadcrumbsLink[]>,
    },
    name: {
      type: String,
    },
  },
  setup() {
    const { isXXlMax, is3XlMax, isSmMax } = useBreakpoints()
    const route = useRoute()

    const resizerRef = ref()
    const detailsContainerRef = ref()
    const sidebarTransition = ref(true)
    const sidebarWidth = ref(400)

    let resizerElement: HTMLElement
    let resizeObserver: ResizeObserver

    const styleUpdate = (elementWidth: number) => {
      const minWidth = 300
      const maxWidth = 400
      sidebarWidth.value = elementWidth <= minWidth ? minWidth : elementWidth >= maxWidth ? maxWidth : elementWidth
    }

    const stopResize = () => {
      sidebarTransition.value = true
      window.removeEventListener('mousemove', resize)
    }

    const resize = (e: MouseEvent) => {
      const offset = detailsContainerRef.value.offsetWidth - (e.pageX - detailsContainerRef.value.getBoundingClientRect().left)
      sidebarTransition.value = false

      styleUpdate(offset)
    }

    const onResizerMouseDown = (e: MouseEvent) => {
      e.preventDefault()
      window.addEventListener('mousemove', resize)
      window.addEventListener('mouseup', stopResize)
    }

    onMounted(() => {
      resizerElement = resizerRef.value

      resizeObserver = new ResizeObserver(() => {
        styleUpdate(sidebarWidth.value)
      })

      if (detailsContainerRef.value) {
        resizeObserver.observe(detailsContainerRef.value)
      }

      resizerElement.addEventListener('mousedown', onResizerMouseDown)
    })

    onUnmounted(() => {
      resizerElement.removeEventListener('mousedown', onResizerMouseDown)
      resizeObserver.disconnect()
    })

    const sidebarVisible = ref(!isXXlMax.value)

    watch(() => isXXlMax.value, () => {
      sidebarVisible.value = !isXXlMax.value
    })

    const toggleSidebar = () => {
      sidebarVisible.value = !sidebarVisible.value
    }

    return {
      is3XlMax,
      sidebarWidth,
      route,
      isXXlMax,
      resizerRef,
      detailsContainerRef,
      sidebarVisible,
      sidebarTransition,
      // breadcrumbs,
      isSmMax,
      toggleSidebar,
    }
  },
})
