
import { defineComponent } from 'vue'
import ActivityCard from '@/components/pages/contacts/contactDetails/activities/ActivityCard.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import { calendar } from '@/services/dateTimeService'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmAvatar,
    ActivityCard,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    avatarColor: {
      type: String,
    },
    date: {
      type: String,
    },
  },
  setup() {
    return {
      calendar,
    }
  },
})
