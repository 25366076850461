import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18b45989"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex align-center justify-between" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "mr-10px lh-24 body-text-semi-bold-14 neutral--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.positionId) + " of " + _toDisplayString(_ctx.positionsTotal) + " " + _toDisplayString(_ctx.positionName ? `${_ctx.positionName}s` : ''), 1),
      _createVNode(_component_tm_button, {
        disable: _ctx.positionId < 2,
        "icon-only": "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('prev')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: "chevron_left",
            size: "xLarge"
          })
        ]),
        _: 1
      }, 8, ["disable"]),
      _createVNode(_component_tm_button, {
        class: "ml-1",
        disable: _ctx.positionId > _ctx.positionsTotal - 1,
        "icon-only": "",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('next')))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_icon, {
            name: "chevron_right",
            size: "xLarge"
          })
        ]),
        _: 1
      }, 8, ["disable"])
    ])
  ]))
}