import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0b6a16e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "activity-card-header" }
const _hoisted_2 = { class: "activity-card__avatar" }
const _hoisted_3 = { class: "activity-card__title" }
const _hoisted_4 = { class: "activity-card__date" }
const _hoisted_5 = { class: "activity-card__date-text" }
const _hoisted_6 = { class: "activity-card__actions" }
const _hoisted_7 = { class: "activity-card-body" }
const _hoisted_8 = { class: "activity-card__status" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_button_more = _resolveComponent("tm-button-more")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["activity-card", {
      'activity-card--log': _ctx.isLog,
      'activity-card--note': _ctx.isNote,
    }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "avatar", {}, () => [
          _createVNode(_component_tm_icon, {
            size: "large",
            class: "primary--text",
            name: _ctx.icon
          }, null, 8, ["name"])
        ], true)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.search)
          ? (_openBlock(), _createBlock(_component_highlight, {
              key: 0,
              search: _ctx.search,
              text: _ctx.title || ''
            }, null, 8, ["search", "text"]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ], 64))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _renderSlot(_ctx.$slots, "date", {}, () => [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.date ? _ctx.calendar(_ctx.date) : ''), 1)
        ], true)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_tm_dropdown, null, {
          menu: _withCtx(() => [
            _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_tm_button_more, {
              flat: "",
              "icon-size": "large",
              color: _ctx.isNote ? 'transparent-note' : 'transparent',
              "btn-size": "small",
              horizontal: ""
            }, null, 8, ["color"])
          ]),
          _: 3
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _renderSlot(_ctx.$slots, "body", {}, () => [
        _createElementVNode("div", {
          class: _normalizeClass(["activity-card__text", {'activity-card__text--note': _ctx.isNote}])
        }, _toDisplayString(_ctx.text), 3)
      ], true),
      _createElementVNode("div", _hoisted_8, [
        _renderSlot(_ctx.$slots, "status", {}, undefined, true)
      ])
    ])
  ], 2))
}