
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmExpansion from '@/components/shared/TmExpansion.vue'

export default defineComponent({
  components: {
    TmExpansion,
    TmButton,
  },
  props: {
    label: {
      type: String,
    },
    caption: {
      type: String,
    },
  },
  emits: ['add-action'],
  setup(props, { emit }) {
    const modelValue = ref(true)
    const addAction = () => {
      modelValue.value = true
      emit('add-action')
    }

    return {
      modelValue,
      addAction,
    }
  },
})
