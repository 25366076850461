
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import { useModals } from '@/compositions/modals'
import { useBreakpoints } from '@/compositions/breakpoints'
import TmEditableText from '@/components/shared/TmEditableText.vue'
import ContactDetailsHeaderActions from '@/components/pages/contacts/contactDetails/ContactDetailsHeaderActions.vue'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'
import TmAvatar from '@/components/shared/TmAvatar.vue'

export default defineComponent({
  components: {
    TmAvatar,
    ContactDetailsHeaderActions,
    TmEditableText,
    TmStatus,
  },
  props: {
    sidebarVisible: {
      type: Boolean,
    },
    contact: {
      type: Object as PropType<ContactLine>,
      required: true,
    },
  },
  emits: ['update:sidebar-visible', 'open-note-editor'],
  setup() {
    const { isSmMax } = useBreakpoints()
    const { openModal } = useModals()

    return {
      openModal,
      isSmMax,
    }
  },
})
