
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    active: {
      type: Boolean,
    },
    coverHeader: {
      type: Boolean,
    },
  },
})
