
import { defineComponent } from 'vue'
import TmRecipient from '@/components/shared/TmRecipient.vue'
import type { PropType } from 'vue'
import type { RecipientType } from '@/definitions/shared/types'

export default defineComponent({
  components: {
    TmRecipient,
  },
  props: {
    items: {
      type: Array as PropType<RecipientType[]>,
    },
    visibleItems: {
      type: Number,
      default: 7,
    },
  },
  emits: ['more-click', 'item-click'],
})
