
import type { PropType } from 'vue'
import { defineComponent, ref, computed } from 'vue'
import TmFieldsContainer from '@/components/shared/tmFieldsContainer/TmFieldsContainer.vue'
import TmSystemInfo from '@/components/shared/TmSystemInfo.vue'
import {
  contactsStatusOptions, assigneeOptions, organizationSizeOptions, industryOptions, tagsOptions
} from '@/definitions/_general/_data/optionsList'
import { utcZonesShort } from '@/definitions/_general/calendar/utc'
import phoneCountryVariant from '@/definitions/_general/_data/countryVariant'
import { useBreakpoints } from '@/compositions/breakpoints'
import { useModes } from '@/compositions/modes'
import type { ContactOrganization } from '@/definitions/contacts/organizations/types'

export default defineComponent({
  components: {
    TmFieldsContainer,
    TmSystemInfo,
  },
  props: {
    isShared: {
      type: Boolean,
    },
    search: {
      type: String,
    },
    organization: {
      type: Object as PropType<ContactOrganization>,
      default: null,
    },
  },
  emits: ['update:search'],
  setup(props) {
    const { isEmptyMode } = useModes()
    const { isMdMax } = useBreakpoints()
    const name = ref(props.organization.organization.organizationName)
    const phones = ref(isEmptyMode.value
      ? []
      : [{ phone: '(990) 338-9983', type: 'Work' }])
    const emails = ref(isEmptyMode.value
      ? []
      : [{ email: 'j.crowford@mail.com', type: 'Work' }])
    const socialMedia = ref(['facebook.com/textmagic', 'twitter.com/textmagic', 'linkedin.com/textmagic'])
    const domains = ref(isEmptyMode.value
      ? []
      : props.organization.domains)

    const statusValue = ref(contactsStatusOptions[0])
    const statusOptions = contactsStatusOptions

    const ownerValue = ref(isEmptyMode.value ? '' : assigneeOptions[3])
    const primaryContactValue = ref(isEmptyMode.value ? '' : props.organization.primaryContact)

    const tagsValue = ref(tagsOptions.slice(0, 2))
    const timezoneCurrent = ref(isEmptyMode.value ? null : utcZonesShort[113].value)
    const country = ref(isEmptyMode.value ? null : phoneCountryVariant[0])
    const organizationSizeValue = ref(isEmptyMode.value ? null : organizationSizeOptions[1])
    const industryValue = ref(isEmptyMode.value ? null : industryOptions[8])

    const interactions = [
      {
        label: 'Total interactions:',
        text: '32',
      },
      {
        label: 'Last interaction',
        text: '11 Jul, 3:14 am',
      },
      {
        label: 'Last outbound',
        text: '11 Jul, 3:14 am',
      },
      {
        label: 'Last inbound',
        text: '11 Jul, 3:14 am',
      },
    ]

    const systemInfo = [
      {
        label: 'ID',
        text: '3225343',
      },
      {
        label: 'Created',
        text: '11 Jul 2021, 3:14 am',
      },
      {
        label: 'Created by',
        text: 'James Smith',
      },
      {
        label: 'Updated',
        text: 'Yesterday, 11:33 pm',
      },
      {
        label: 'Updated by',
        text: 'James Smith',
      },
    ]

    const commonProps = computed(() => {
      return {
        leftLabel: !isMdMax.value,
        search: props.search,
        inputContainerClass: isMdMax.value ? 'mx-n2' : '',
      }
    })

    return {
      isMdMax,
      name,
      phones,
      emails,
      socialMedia,
      domains,
      statusValue,
      statusOptions,
      ownerValue,
      primaryContactValue,
      assigneeOptions,
      tagsValue,
      tagsOptions,
      timezoneCurrent,
      country,
      organizationSizeValue,
      organizationSizeOptions,
      industryValue,
      industryOptions,
      interactions,
      systemInfo,
      commonProps,
    }
  },
})
