
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import ActivityCard from '@/components/pages/contacts/contactDetails/activities/ActivityCard.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { ActivityScheduledStatus } from '@/definitions/contacts/activities/new/types'
import { activityScheduleIcon } from '@/definitions/contacts/activities/new/data'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'

export default defineComponent({
  components: {
    TmDropdownItem,
    TmStatus,
    ActivityCard,
  },
  props: {
    status: {
      type: String as PropType<ActivityScheduledStatus>,
      required: true,
    },
  },
  setup() {
    return {
      activityScheduleIcon,
    }
  },
})
