import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ef343af"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tm-recipients" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_recipient = _resolveComponent("tm-recipient")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items.slice(0, _ctx.visibleItems), (item, i) => {
      return (_openBlock(), _createBlock(_component_tm_recipient, _mergeProps({ key: i }, item, {
        onClick: ($event: any) => (_ctx.$emit('item-click', item))
      }), null, 16, ["onClick"]))
    }), 128)),
    (_ctx.items.length - _ctx.visibleItems > 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "tm-recipients__more",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('more-click')))
        }, " +" + _toDisplayString(_ctx.items.length - _ctx.visibleItems), 1))
      : _createCommentVNode("", true)
  ]))
}