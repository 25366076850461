import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0406740"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex w100pr align-center pb-3" }
const _hoisted_2 = { class: "contact-details-expansion__header" }
const _hoisted_3 = { class: "contact-details-expansion__label" }
const _hoisted_4 = { class: "contact-details-expansion__caption" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_expansion = _resolveComponent("tm-expansion")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["contact-details-expansion", { 'contact-details-expansion--collapsed': !_ctx.modelValue }])
  }, [
    _createVNode(_component_tm_expansion, {
      modelValue: _ctx.modelValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelValue) = $event)),
      caption: "caption",
      "hide-expansion-btn": "",
      size: "small"
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.caption), 1)
            ])
          ]),
          _createVNode(_component_tm_button, {
            color: "transparent",
            flat: "",
            "icon-only": "",
            size: "small",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.modelValue = !_ctx.modelValue), ["stop","prevent"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                size: "xxLarge",
                class: "neutral--text",
                name: _ctx.modelValue ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
              }, null, 8, ["name"])
            ]),
            _: 1
          }),
          _createVNode(_component_tm_button, {
            flat: "",
            "icon-only": "",
            size: "medium",
            class: "ml-auto",
            onClick: _withModifiers(_ctx.addAction, ["stop","prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_tm_icon, {
                size: "medium",
                class: "neutral--text",
                name: "add"
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    }, 8, ["modelValue"])
  ], 2))
}